import { getCookie } from "./utils";
export const waitForAdobeTargetCookie = async ()=>{
    const mboxRegex = // eslint-disable-next-line unicorn/better-regex
    /[a-zA-Z]+#[a-zA-Z0-9]+#\d+\|[a-zA-Z]+#[a-zA-Z0-9._]+#\d+/g;
    let timeoutId;
    try {
        const promise = new Promise((resolve)=>{
            const interval = setInterval(()=>{
                const mboxCookie = getCookie({
                    name: "mbox"
                });
                if (mboxCookie && mboxRegex.test(mboxCookie)) {
                    clearInterval(interval);
                    resolve();
                }
            }, 100);
        });
        const timeout = new Promise((resolve)=>{
            timeoutId = setTimeout(()=>resolve(), 3000);
        });
        await Promise.race([
            promise,
            timeout
        ]);
    } finally{
        clearTimeout(timeoutId);
    }
};
