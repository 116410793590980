export const mapPreferenceUpdateToUpdatedPreferences = (updatePreferences)=>{
    const updatedUserProfilePreferences = {};
    if (updatePreferences.preferenceCall) {
        updatedUserProfilePreferences.preferenceCall = updatePreferences.preferenceCall.value;
    }
    if (updatePreferences.preferenceEmail) {
        updatedUserProfilePreferences.preferenceEmail = updatePreferences.preferenceEmail.value;
    }
    if (updatePreferences.preferenceSms) {
        updatedUserProfilePreferences.preferenceSms = updatePreferences.preferenceSms.value;
    }
    return updatedUserProfilePreferences;
};
