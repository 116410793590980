export const environmentDev = {
    FRED: "THIS IS A DUMMY VALUE",
    I_DONT_WANT_THIS_CAR: "956475",
    FOMO_EXPERIMENT_ID: "1084152",
    REQUEST_CALLBACK_EXPERIMENT_ID: "1071562",
    CLIENT_PROD_TEST: "1110683",
    NEXT_BEST_ACTION_EXPERIMENT_ID: "1115849",
    SEARCH_MULTIPLE_IMAGES_V2_EXPERIMENT_ID: "1138278",
    // SSR Enabled Activities
    SSR_PROD_TEST: "1084587",
    // Activity Location IDs
    HOMEPAGE_SSR_TEST: "homepage-hmc-flag",
    BUY_NOW_CTA_MESSAGING_EXPERIMENT_ID: "1127950"
};
