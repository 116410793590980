const capitaliseFirstLetter = (string)=>string.replace(/(^|\s|-)([a-z])/g, (_, leadingWhitespaceOrHyphen, lowercaseLetter)=>leadingWhitespaceOrHyphen + lowercaseLetter.toUpperCase());
export const generateStoreType = (storeName, shouldCapitaliseStoreType = false)=>{
    const normalisedStoreName = storeName.toLowerCase();
    const isCorby = normalisedStoreName === "corby";
    const storeType = isCorby ? "warehouse" : "store";
    return shouldCapitaliseStoreType ? capitaliseFirstLetter(storeType) : storeType;
};
export const generateStoreNameWithType = (storeName, shouldCapitaliseStoreType = false)=>{
    const normalisedStoreName = storeName.toLowerCase();
    const capitalisedStoreName = capitaliseFirstLetter(normalisedStoreName);
    const capitalisedStoreType = generateStoreType(storeName, shouldCapitaliseStoreType);
    return `cinch ${capitalisedStoreType} ${capitalisedStoreName}`;
};
