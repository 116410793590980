import useSWRImmutable from "swr/immutable";
import axios from "axios";
import { TrustpilotReviewsResponse } from "../api/get-reviews";
import { readFromEnv, Env } from "@cinch-nx/environments";
const httpClient = axios.create();
const fetcher = async (url)=>await httpClient.get(url).then((res)=>TrustpilotReviewsResponse.parse(res.data));
export const useTrustpilotReviewCount = ()=>{
    const TRUSTPILOT_URL_FROM_ENV = `${readFromEnv(Env.TrustpilotUrl)}/trustpilot-get-reviews`;
    const { data  } = useSWRImmutable(TRUSTPILOT_URL_FROM_ENV, fetcher);
    return data === null || data === void 0 ? void 0 : data.total;
};
