import { getToken, isSSR } from "@cinch-labs/shared-util";
import { Env, readFromEnv } from "@cinch-nx/environments";
import { datadogRum } from "@datadog/browser-rum";
import axios from "axios";
import { create } from "zustand";
import { mapPreferenceUpdateToUpdatedPreferences } from "./util/preferences";
let userStoreConfig = {
    authKey: "",
    legacyAuthKey: "",
    profileServiceUrl: "",
    identityServiceUrl: "",
    auth0ServiceUrl: "",
    auth0DatabaseName: "",
    auth0ClientId: "",
    redirectUrlKey: ""
};
let httpClient;
let onSessionExpiredCallback;
export const initUserStore = (config, axiosImpl = axios)=>{
    userStoreConfig = {
        identityServiceUrl: readFromEnv(Env.IdentityServiceUrl),
        auth0ServiceUrl: readFromEnv(Env.Auth0ServiceUrl),
        auth0DatabaseName: readFromEnv(Env.Auth0DatabaseName),
        auth0ClientId: readFromEnv(Env.Auth0ClientId),
        profileServiceUrl: readFromEnv(Env.ProfileServiceUrl),
        redirectUrlKey: readFromEnv(Env.RedirectUrlKey),
        authKey: readFromEnv(Env.AuthKey),
        legacyAuthKey: readFromEnv(Env.LegacyAuthKey),
        ...config
    };
    const _httpClient = axiosImpl.create();
    _httpClient.interceptors.request.use((request)=>{
        const token = getToken({
            authKey: userStoreConfig.authKey,
            legacyAuthKey: userStoreConfig.legacyAuthKey
        });
        request.headers = {
            ...request.headers,
            Authorization: `Bearer ${token === null || token === void 0 ? void 0 : token.access_token}`
        };
        return request;
    });
    _httpClient.interceptors.response.use(undefined, (error)=>{
        var ref, ref1;
        if ((error === null || error === void 0 ? void 0 : (ref = error.response) === null || ref === void 0 ? void 0 : ref.status) === 401 || (error === null || error === void 0 ? void 0 : (ref1 = error.response) === null || ref1 === void 0 ? void 0 : ref1.status) === 403) {
            onSessionExpiredCallback === null || onSessionExpiredCallback === void 0 ? void 0 : onSessionExpiredCallback();
        }
        return Promise.reject(error);
    });
    httpClient = _httpClient;
};
export const useUserStore = create((set, get)=>{
    onSessionExpiredCallback = ()=>{
        set({
            status: "expired"
        });
    };
    return {
        status: "unknown",
        setStatus: (status)=>set({
                status
            }),
        fetchUser: async ()=>{
            const { authKey , legacyAuthKey , profileServiceUrl  } = userStoreConfig;
            if (isSSR()) {
                return;
            }
            const token = getToken({
                authKey,
                legacyAuthKey
            });
            if (!token) {
                return set(()=>({
                        status: "invalid"
                    }));
            }
            set(()=>({
                    status: "pending",
                    token
                }));
            try {
                const profile = (await httpClient.get(`${profileServiceUrl}/v2/userprofile`)).data;
                const { preferenceCall , preferenceEmail , preferenceSms , ...profileWithoutPreferences } = profile;
                return set(()=>({
                        status: "valid",
                        profile: profileWithoutPreferences,
                        preferences: {
                            preferenceCall,
                            preferenceEmail,
                            preferenceSms
                        },
                        token
                    }));
            } catch  {
                datadogRum.addError(new Error("FetchUserProfileActionFailed"));
            }
        },
        updatePassword: async (oldPassword, newPassword)=>{
            try {
                const { identityServiceUrl  } = userStoreConfig;
                set(()=>({
                        status: "updating"
                    }));
                await httpClient.put(`${identityServiceUrl}/password`, {
                    oldPassword,
                    newPassword
                });
                set(()=>({
                        status: "valid"
                    }));
            } catch  {
                datadogRum.addError(new Error("UpdatePasswordActionFailed"));
            }
        },
        resetPassword: async (email)=>{
            try {
                const postUrl = "/dbconnections/change_password";
                const { auth0ServiceUrl , auth0DatabaseName , auth0ClientId  } = userStoreConfig;
                const data = {
                    email: email,
                    connection: auth0DatabaseName,
                    client_id: auth0ClientId
                };
                const axiosInstance = axios.create({
                    baseURL: auth0ServiceUrl,
                    validateStatus: ()=>true
                });
                const result = await axiosInstance.post(postUrl, data);
                return {
                    statusCode: result.status
                };
            } catch (error) {
                datadogRum.addError(new Error("ResetPasswordActionFailed"));
                throw error;
            }
        },
        updateProfile: async (updatedFields)=>{
            try {
                const { profileServiceUrl  } = userStoreConfig;
                set(()=>({
                        status: "updating"
                    }));
                const fullProfile = {
                    ...get().profile,
                    ...updatedFields
                };
                const updatedProfile = {
                    ...updatedFields
                };
                await httpClient.put(`${profileServiceUrl}/v2/userprofile`, updatedProfile);
                set(()=>({
                        profile: fullProfile,
                        status: "valid"
                    }));
            } catch  {
                datadogRum.addError(new Error("UpdateProfileActionFailed"));
            }
        },
        updateProfilePreferences: async (preferenceUpdate)=>{
            try {
                const { profileServiceUrl  } = userStoreConfig;
                set(()=>({
                        status: "updating"
                    }));
                await httpClient.put(`${profileServiceUrl}/v2/userprofile`, {
                    ...preferenceUpdate,
                    context: "Updated from Marketing Preferences page in profile"
                });
                const existingPreferences = get().preferences;
                set(()=>({
                        preferences: {
                            ...existingPreferences,
                            ...mapPreferenceUpdateToUpdatedPreferences(preferenceUpdate)
                        },
                        status: "valid"
                    }));
            } catch  {
                datadogRum.addError(new Error("UpdateProfilePreferencesActionFailed"));
            }
        },
        logout: ()=>{
            const { redirectUrlKey  } = userStoreConfig;
            localStorage.removeItem(redirectUrlKey);
            window.location.assign("/logout");
        },
        login: ()=>{
            window.location.assign("/login");
        },
        register: ()=>{
            window.location.assign("/login");
        }
    };
});
